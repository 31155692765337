import styled from 'styled-components';
import { INVOICE_STATUSES } from 'shared/constants/INVOICES';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';

const CONTAINER_BG_COLOR = {
  [INVOICE_STATUSES.NEW]: '#E7E7E7',
  [INVOICE_STATUSES.WAIT]: '#BCD6F9',
  [INVOICE_STATUSES.IN_WORK]: '#FAE0AD',
  [INVOICE_STATUSES.IN_ORDER]: '#BFF2D6',
  [INVOICE_STATUSES.CANCEL]: '#F9C9C9',
};

export default {
  Container: styled.div<{ status?: string }>`
    height: 20px;
    width: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-bottom: 1px;
    border-radius: 6px;
    background: #e7e7e7;
    flex-shrink: 0;
    font-size: 12px;
    text-transform: uppercase;

    background-color: ${({ status }) => CONTAINER_BG_COLOR[status]};

    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      margin-right: 8px;
    }
  `,
};

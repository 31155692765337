import head from 'lodash/head';
import React, { useRef } from 'react';
import MODALS from 'shared/constants/MODALS';
import SEAT_SELECTOR_TYPES from 'shared/constants/SEAT_SELECTOR_TYPES';
import { TICKET_STATUSES_VALUES } from 'shared/constants/TICKET_TYPES';
import { orderTickets } from 'shared/helpers/tickets';
import { ModalFunctions } from '../../../interfaces/modal';
import TableSeatCheckbox from '../TableSeatCheckbox';
import TicketOrderStatus from '../TicketOrderStatus';
import TicketStatus from '../TicketStatus';
import NumericSelector from './NumericSelector';
import Styled from './styles';

interface SelectorProps {
  announcement: any;
  selectSeat: (data: any) => void;
  selectedAnnouncements?: any[];
  editMode?: boolean;
  maxSeats?: number;
  selectedCurrency?: any;
  openModal?: ModalFunctions['openModal'];
  closeModal?: ModalFunctions['closeModal'];
}

const getModalText = (selectedCurrency) => `
    Билеты с ${selectedCurrency.char_code} валютой будут удалены.
    Вы уверены, что хотите совершить это действие?
  `;

const TableSeatsSelector: React.FC<SelectorProps> = ({
  announcement,
  selectSeat,
  selectedAnnouncements = [],
  editMode,
  maxSeats,
  selectedCurrency,
  openModal,
  closeModal,
}) => {
  const currencyIsSame = !selectedCurrency || announcement.currency.id === selectedCurrency.id;
  const numericInput = useRef<any>();

  const handleSeatSelect = (ticket) => {
    const addSeat = (shouldClear = false) => {
      selectSeat({
        announcement,
        ticket,
        shouldClear,
        type: SEAT_SELECTOR_TYPES.PLAIN,
      });

      if (closeModal) closeModal();
    };

    if (!currencyIsSame) {
      return openModal(MODALS.CONFIRM_MODAL, {
        onSubmit: () => addSeat(true),
        title: 'Обновить заказ',
        text: getModalText(selectedCurrency),
        modalData: { size: 400 },
      });
    }

    return addSeat();
  };

  const handleNumbericSeatSelect = (ticket, count) => {
    const addNumericSeat = (shouldClear?) => {
      numericInput.current.setState((prevState) => ({
        ...prevState,
        value: count,
      }));

      selectSeat({
        announcement,
        ticket,
        count,
        shouldClear,
        type: SEAT_SELECTOR_TYPES.NUMBERED,
      });

      if (closeModal) closeModal();
    };

    if (!currencyIsSame) {
      return openModal(MODALS.CONFIRM_MODAL, {
        onSubmit: () => addNumericSeat(true),
        onReject: () => {
          numericInput.current.setState((prevState) => ({
            ...prevState,
            value: 0,
          }));

          closeModal();
        },
        title: 'Обновить заказ',
        text: getModalText(selectedCurrency),
        modalData: { size: 400 },
      });
    }

    return addNumericSeat();
  };

  const firstTicket: any = head(announcement.tickets);
  const currentAnnouncement = selectedAnnouncements.find(
    (announcementItem) => announcementItem.id === announcement.id,
  );

  const renderTicket = (ticket) => {
    if (ticket.status === TICKET_STATUSES_VALUES.NEW) {
      return (
        <TableSeatCheckbox
          key={ticket.id}
          ticket={ticket}
          onChange={handleSeatSelect}
          checked={
            currentAnnouncement
              ? Boolean(
                  currentAnnouncement.selectedTickets.find(
                    (selectedTicket) => selectedTicket.id === ticket.id,
                  ),
                )
              : false
          }
          editMode={editMode}
          isPreorder={ticket.isPreorderTicket}
        />
      );
    }

    if (ticket.order && ticket.order.destination) {
      return (
        <TicketOrderStatus order={ticket.order} key={ticket.id}>
          <div data-selenium="seat_ordered" id={`${ticket.order.id}`}>
            <TicketStatus
              status={ticket.status}
              order={ticket.order}
              isPreorderTicket={ticket.isPreorderTicket}
            >
              {ticket.seat}
            </TicketStatus>
          </div>
        </TicketOrderStatus>
      );
    }

    return (
      <TicketStatus
        status={ticket.status}
        order={ticket.order}
        key={ticket.id}
        isPreorderTicket={ticket.isPreorderTicket}
      >
        {ticket.seat}
      </TicketStatus>
    );
  };

  if (!firstTicket) return null;

  return (
    <Styled.Container seats={firstTicket.seat} data-selenium="announcement-tickets">
      {firstTicket.seat ? (
        <Styled.CheckboxContainer>
          {orderTickets(announcement.tickets).map((ticket) => renderTicket(ticket))}
        </Styled.CheckboxContainer>
      ) : (
        <NumericSelector
          data={firstTicket}
          onChange={handleNumbericSeatSelect}
          value={currentAnnouncement && currentAnnouncement.selectedTickets}
          max={maxSeats || announcement.count}
          name="announcement-tickets-counter"
          disabled={editMode ? false : announcement.count === 0}
          ref={numericInput}
        />
      )}
    </Styled.Container>
  );
};

export default TableSeatsSelector;

import React from 'react';
import { INVOICE_STATUSES } from 'shared/constants/INVOICES';
import preventClick from 'shared/helpers/preventClick';
import routes from 'shared/helpers/routes';
import LinkIcon from '../../../../../static/icons/chain.svg';
import CommentDotsIcon from '../../../../../static/icons/comment-dots.svg';
import Link from '../../../Link';
import Tooltip from '../../../Tooltip';
import Styled from './styles';

interface InfoProps {
  data: any;
  isDesktop?: boolean;
}

const InvoicesShortInfo: React.FC<InfoProps> = ({ data, isDesktop = true }) => {
  const { status, comment, reject_reason: rejectReason, order } = data;

  const text = comment || rejectReason;

  if (status === INVOICE_STATUSES.IN_ORDER) {
    return (
      <Styled.Container onClick={preventClick}>
        <Tooltip text={`В заказе ${order}`}>
          <Link href={routes.sale(order)}>
            <LinkIcon fill="#2F80ED" />
          </Link>
        </Tooltip>
      </Styled.Container>
    );
  }

  if (text) {
    return (
      <Tooltip text={<Styled.Text>{text}</Styled.Text>} placement={!isDesktop ? 'left' : 'top'}>
        <Styled.Container>
          <CommentDotsIcon />
        </Styled.Container>
      </Tooltip>
    );
  }

  return null;
};

export default InvoicesShortInfo;

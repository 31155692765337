import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  Row: styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    @media (max-width: ${BREAKPOINTS.XL}) {
      flex-wrap: wrap;
      align-items: flex-start;
    }
  `,

  Link: styled.a`
    position: relative;
    display: flex;
    align-items: center;
    padding: 9px 24px;
    color: #000;

    &:hover {
      background: #f5f5f5;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      padding: 9px 16px;
    }
  `,

  Info: styled.div`
    display: flex;
    width: 45%;

    @media (max-width: ${BREAKPOINTS.XL}) {
      width: 100%;
    }
  `,

  Date: styled.div`
    flex-shrink: 0;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.64);

    @media (max-width: ${BREAKPOINTS.XL}) {
      max-width: 100%;
      flex-grow: 1;
    }
  `,

  Icon: styled.div`
    margin-right: 24px;
    fill: rgba(0, 0, 0, 0.24);

    @media (max-width: ${BREAKPOINTS.LAPTOP}) {
      display: none;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: block;
      flex-shrink: 0;
      margin-right: 8px;

      & > svg {
        height: 14px;
      }
    }
  `,

  Id: styled.div`
    flex-shrink: 0;
    width: 60px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;

    @media (max-width: ${BREAKPOINTS.XL}) {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 1;
    }
  `,

  TableUserInfo: styled.div``,

  EventInfo: styled.div`
    width: 20%;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 8px;

    @media (max-width: ${BREAKPOINTS.XL}) {
      align-self: center;
      min-width: 30%;
      flex-grow: 1;
      padding: 0;
    }
  `,

  Count: styled.div`
    flex-shrink: 0;
    width: 40px;

    @media (max-width: ${BREAKPOINTS.XL}) {
      align-self: center;
    }
  `,

  StatusAndManager: styled.div`
    display: flex;

    @media (max-width: ${BREAKPOINTS.XL}) {
      flex-direction: column;
    }
  `,

  InvoiceStatusAndShortInfo: styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 150px;

    @media (max-width: ${BREAKPOINTS.XL}) {
      width: 130px;
      height: 40px;
    }
  `,

  Manager: styled.div``,
};

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import React from 'react';
import { connect } from 'react-redux';
import { toggleSidebarState } from 'entities/layout';
import Styled from './styles';

interface ContainerProps {
  sidebarIsOpen: boolean;
  toggleSidebarState: () => void;
}

const SidebarIconWrapper = (props: ContainerProps) => (
  <Styled.Container onClick={props.toggleSidebarState}>
    {props.sidebarIsOpen ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
  </Styled.Container>
);

const mapStateToProps = (state: any) => ({
  sidebarIsOpen: state.layout.sidebarIsOpen,
});
const mapDispatchToProps = {
  toggleSidebarState,
};
export default connect(mapStateToProps, mapDispatchToProps)(SidebarIconWrapper);

import { Button, ButtonProps } from 'antd';
import styled, { css, RuleSet } from 'styled-components';
import { GLOBAL_COLORS } from '../../constants/COLORS';

type TVariantStyles = Record<'link' | 'text' | 'outlined' | 'dashed' | 'solid' | 'filled', RuleSet<object>>;

const stylesByColorVariant: Record<'default' | 'primary' | 'danger', Partial<TVariantStyles>> = {
  primary: {
    solid: css`
      background: ${GLOBAL_COLORS.colorPrimary};
      color: ${GLOBAL_COLORS.colorText};

      &:not(:disabled):not(.ant-btn-disabled):hover {
        background: ${GLOBAL_COLORS.colorPrimaryHover};
        color: ${GLOBAL_COLORS.colorTextSecondary};
      }

      &:not(:disabled):not(.ant-btn-disabled):active,
      &:not(:disabled):not(.ant-btn-disabled):focus {
        background: ${GLOBAL_COLORS.colorPrimaryActive};
      }
    `,
    outlined: css`
      border-color: ${GLOBAL_COLORS.colorPrimary};
      color: ${GLOBAL_COLORS.colorText};
      background: ${GLOBAL_COLORS.colorPrimary};

      &:not(:disabled):not(.ant-btn-disabled):hover {
        color: ${GLOBAL_COLORS.colorTextSecondary};
        background: ${GLOBAL_COLORS.colorBgTextHover};
      }
    `,
    dashed: css`
      border-color: ${GLOBAL_COLORS.colorPrimary};
      color: ${GLOBAL_COLORS.colorText};

      &:not(:disabled):not(.ant-btn-disabled):hover {
        color: ${GLOBAL_COLORS.colorTextSecondary};
        border-color: ${GLOBAL_COLORS.colorPrimaryHover};
      }
    `,
  },
  default: {
    solid: css`
      color: ${GLOBAL_COLORS.colorText};
      background: ${GLOBAL_COLORS.colorBgContainer};

      &:not(:disabled):not(.ant-btn-disabled):hover {
        color: ${GLOBAL_COLORS.colorTextSecondary};
        background: ${GLOBAL_COLORS.colorBgTextHover};
      }
    `,
    outlined: css`
      border-color: ${GLOBAL_COLORS.colorBorder};
      color: ${GLOBAL_COLORS.colorText};

      &:not(:disabled):not(.ant-btn-disabled):hover {
        color: ${GLOBAL_COLORS.colorTextSecondary};
        border-color: ${GLOBAL_COLORS.colorBorder};
      }

      &:not(:disabled):not(.ant-btn-disabled):focus {
        border-color: ${GLOBAL_COLORS.colorPrimaryActive};
      }
    `,
    text: css`
      &:not(:disabled):not(.ant-btn-disabled):hover {
        background: ${GLOBAL_COLORS.colorBgTextHover};
        color: ${GLOBAL_COLORS.colorTextSecondary};
      }

      &:not(:disabled):not(.ant-btn-disabled):active {
        color: ${GLOBAL_COLORS.colorText};
      }

      &:not(:disabled):not(.ant-btn-disabled):focus {
        color: ${GLOBAL_COLORS.colorText};
        background: ${GLOBAL_COLORS.colorBgTextActive};
      }
    `,
    link: css`
      color: ${GLOBAL_COLORS.colorLink};

      &:not(:disabled):not(.ant-btn-disabled):hover {
        color: ${GLOBAL_COLORS.colorLinkHover};
      }

      &:not(:disabled):not(.ant-btn-disabled):active {
        color: ${GLOBAL_COLORS.colorLinkActive};
      }

      &:not(:disabled):not(.ant-btn-disabled):focus {
        color: ${GLOBAL_COLORS.colorLinkActive};
        border-color: ${GLOBAL_COLORS.colorLinkActive};
      }
    `,
  },
  danger: {
    solid: css`
      background: ${GLOBAL_COLORS.colorError};

      &:not(:disabled):not(.ant-btn-disabled):hover {
        background: ${GLOBAL_COLORS.colorErrorHover};
      }

      &:not(:disabled):not(.ant-btn-disabled):focus {
        background: ${GLOBAL_COLORS.colorErrorActive};
      }
    `,
    outlined: css`
      border-color: ${GLOBAL_COLORS.colorBorder} !important;
      color: ${GLOBAL_COLORS.colorText} !important;

      &:not(:disabled):not(.ant-btn-disabled):hover {
        border-color: ${GLOBAL_COLORS.colorErrorHover} !important;
        color: ${GLOBAL_COLORS.colorErrorHover} !important;
      }

      &:not(:disabled):not(.ant-btn-disabled):focus {
        border-color: ${GLOBAL_COLORS.colorErrorActive} !important;
        color: ${GLOBAL_COLORS.colorErrorActive} !important;
      }
    `,
    text: css`
      color: ${GLOBAL_COLORS.colorError};

      &:not(:disabled):not(.ant-btn-disabled):hover {
        background: ${GLOBAL_COLORS.colorErrorBg};
        color: ${GLOBAL_COLORS.colorErrorHover};
      }

      &:not(:disabled):not(.ant-btn-disabled):focus {
        background: ${GLOBAL_COLORS.colorErrorBg};
        color: ${GLOBAL_COLORS.colorErrorActive};
      }
    `,
    link: css`
      color: ${GLOBAL_COLORS.colorError};

      &:not(:disabled):not(.ant-btn-disabled):hover {
        color: ${GLOBAL_COLORS.colorErrorHover};
      }

      &:not(:disabled):not(.ant-btn-disabled):focus {
        color: ${GLOBAL_COLORS.colorErrorActive};
      }
    `,
    dashed: css`
      border-color: ${GLOBAL_COLORS.colorError};
      color: ${GLOBAL_COLORS.colorError};

      &:not(:disabled):not(.ant-btn-disabled):hover {
        color: ${GLOBAL_COLORS.colorErrorHover};
        border-color: ${GLOBAL_COLORS.colorErrorHover};
      }

      &:not(:disabled):not(.ant-btn-disabled):focus {
        border-color: ${GLOBAL_COLORS.colorErrorActive};
        color: ${GLOBAL_COLORS.colorErrorActive};
      }
    `,
  },
};

export const ButtonStyled = styled(Button)<{
  color: ButtonProps['color'];
  variant: ButtonProps['variant'];
  fullWidth?: boolean;
}>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'unset')};

  ${({ color = 'default', variant = 'solid' }) =>
    stylesByColorVariant[color][variant] ? stylesByColorVariant[color][variant] : ''};
`;

import React from 'react';
import { INVOICE_STATUSES } from 'shared/constants/INVOICES';
import Styled from './styles';

const TEXT = {
  [INVOICE_STATUSES.NEW]: 'Не в работе',
  [INVOICE_STATUSES.WAIT]: 'Ожидание',
  [INVOICE_STATUSES.IN_WORK]: 'В работе',
  [INVOICE_STATUSES.IN_ORDER]: 'В заказе',
  [INVOICE_STATUSES.CANCEL]: 'Отмена',
};
const InvoicesStatus = ({ data }: any) => {
  const { status } = data;

  return <Styled.Container status={status}>{TEXT[status]}</Styled.Container>;
};

export default InvoicesStatus;

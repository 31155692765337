import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import COLORS from 'shared/constants/COLORS';
import Button from '../Button';
import TableUserInfoStyles from '../TableUserInfo/styles';

interface CSSProps {
  focused?: boolean;
  small?: boolean;
  sidebarIsOpen?: boolean;
  circle?: string;
}

export default {
  Container: styled.div<CSSProps>`
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-grow: ${({ focused }) => focused && 1};
    margin-left: auto;

    @media (max-width: ${BREAKPOINTS.XL}) {
      padding: 0;
      align-items: center;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      ${(props) =>
        props.focused &&
        css`
          position: fixed;
          top: 0;
          left: 0;
          height: 56px;
          width: 100%;
          z-index: 2;
        `}
    }
  `,

  SearchButton: styled(Button)<CSSProps>`
    display: ${({ focused }) => (focused ? 'none' : 'flex')};
    align-items: center;
    justify-content: center;
    border-radius: ${({ circle }) => circle && '50%'};
    transition: all 0.2s ease-in-out;
    flex-shrink: 0;
    cursor: ${(props) => (props.focused ? 'default' : 'pointer')};
    background: #fff;
    z-index: 1;

    svg {
      opacity: ${(props) => props.focused && 0.4};
    }

    &:hover:not(:disabled) {
      background-color: ${(props) => props.focused && '#fff'};
    }
  `,

  InputContainer: styled.div<CSSProps>`
    position: relative;
    width: 100%;
    max-width: 0;
    opacity: 0;
    overflow: hidden;

    transition: opacity 0.2s, max-width 0.2s;
    transition-timing-function: linear;

    ${(props) =>
      props.focused &&
      css`
        max-width: 100%;
        opacity: 1;

        @media (max-width: ${BREAKPOINTS.MD}) {
          padding: 0 8px;
        }
      `}
  `,

  Input: styled.input<any>`
    outline: none;
    border: 0;
    box-sizing: border-box;
    flex-grow: 1;
    font-size: 16px;
    width: 100%;
    height: 40px;

    &::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.64);
    }

    &::-moz-placeholder {
      color: rgba(0, 0, 0, 0.64);
    }

    &:-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.64);
    }

    &:-moz-placeholder {
      color: rgba(0, 0, 0, 0.64);
    }
  `,

  Actions: styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  `,

  Hint: styled.div`
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    line-height: 16px;
    text-align: right;

    color: rgba(0, 0, 0, 0.4);

    @media (max-width: ${BREAKPOINTS.XL}) {
      display: none;
    }
  `,

  HintKey: styled.a`
    color: ${COLORS.BLUE};
  `,

  Close: styled.button`
    display: inline-block;
    vertical-align: middle;
    padding: 0;
    width: 40px;
    height: 40px;
    margin-left: 16px;
    border-radius: 8px;
    background: transparent;
    border: 0;
    outline: none;
    transition: all 0.2s ease-in-out;
    flex-shrink: 0;
    cursor: pointer;

    &:hover {
      background: #f5f5f5;
    }
  `,

  DropMenu: styled.div`
    position: absolute;
    left: 0;
    top: calc(100% + 8px);
    width: 100%;
    max-height: 85vh;
    padding: 8px 0;
    overflow: auto;
    margin-top: 1px;
    background: #fff;
    box-shadow: 0px 9px 28px 8px #0000000d, 0px 3px 6px -4px #0000001f, 0px 6px 16px 0px #00000014;
    border-radius: 8px;
    border: 1px solid #f0f0f0;
    z-index: 6;
  `,

  Results: styled.div`
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: auto;

    &:not(:last-child) {
      padding-bottom: 4px;
      margin-bottom: 4px;
      border-bottom: 1px solid #e1e1e1;
    }
  `,

  Result: styled.div`
    margin: 0;
    padding: 0;
    list-style: none;
  `,

  Link: styled.a`
    position: relative;
    display: flex;
    align-items: center;
    padding: 9px 24px;
    color: #000;

    &:hover {
      background: #f5f5f5;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      padding: 9px 16px;
    }
  `,

  Icon: styled.div`
    margin-right: 25px;
    fill: rgba(0, 0, 0, 0.24);

    @media (max-width: ${BREAKPOINTS.LAPTOP}) {
      display: none;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: block;
      flex-shrink: 0;
      margin-right: 8px;

      & > svg {
        height: 14px;
      }
    }
  `,

  Id: styled.div`
    margin-right: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #2f80ed;

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: flex;
      align-items: center;
      font-size: 12px;
    }
  `,

  ExternalId: styled.div`
    font-size: 12px;
    color: rgba(0, 0, 0, 0.64);

    @media (max-width: ${BREAKPOINTS.MD}) {
      margin-left: 8px;
    }
  `,

  Name: styled.div<CSSProps>`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 8px;
    font-size: 16px;
    line-height: 21px;

    ${(props) =>
      props.small &&
      css`
        @media (max-width: ${BREAKPOINTS.LAPTOP}) {
          max-width: ${props.sidebarIsOpen ? '150px' : '240px'};
        }
      `}

    @media (max-width: ${BREAKPOINTS.XL}) {
      max-width: 100%;
    }
  `,

  Date: styled.div<CSSProps>`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.64);

    ${(props) =>
      props.small &&
      css`
        @media (max-width: ${BREAKPOINTS.LAPTOP}) {
          max-width: ${props.sidebarIsOpen ? '150px' : '240px'};
        }
      `}

    @media (max-width: ${BREAKPOINTS.XL}) {
      max-width: 100%;
    }
  `,

  Title: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 24px;
    color: rgba(0, 0, 0, 0.64);
    font-size: 12px;

    &:hover {
      background: #f5f5f5;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      padding: 7px 16px;
    }
  `,

  Caret: styled.span`
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    opacity: 0.4;
  `,

  Type: styled.div`
    width: 160px;
    margin-left: 45px;
    flex-shrink: 0;
    font-size: 12px;
    line-height: 16px;
  `,

  TypeIcon: styled.span`
    margin-right: 8px;
    vertical-align: middle;
  `,

  Row: styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media (max-width: ${BREAKPOINTS.MD}) {
      flex-wrap: wrap;
    }
  `,

  Column: styled.div<{ croppedText?: boolean }>`
    display: flex;
    align-items: center;

    ${(props) =>
      props.croppedText &&
      css`
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      `}

    @media (max-width: ${BREAKPOINTS.XL}) {
      min-width: 0;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      flex-wrap: wrap;
      width: 100%;
    }
  `,

  Tickets: styled.div`
    box-sizing: border-box;
    flex-shrink: 0;
    padding-right: 24px;

    @media (max-width: ${BREAKPOINTS.XL}) {
      box-sizing: border-box;
      flex-shrink: 1;
      width: calc(100% - 110px);
      padding-right: 16px;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: 100%;
      padding-right: 0;
      font-size: 12px;
      line-height: 21px;

      & div {
        max-width: 100%;
      }
    }
  `,

  Empty: styled.div`
    padding: 16px;
    text-align: center;
  `,

  Customer: styled.div`
    flex-shrink: 0;
    width: 110px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      position: absolute;
      top: 10px;
      right: 16px;
      display: flex;
      align-items: center;
      width: auto;
      max-width: 45%;

      ${TableUserInfoStyles.Container} {
        width: auto;
        flex-shrink: 1;
        min-width: 0;
      }

      ${TableUserInfoStyles.Username} {
        font-size: 12px;
        line-height: 1;
        white-space: nowrap;
      }
    }
  `,

  OrderStatusWrapper: styled.div`
    margin-right: 24px;
    width: 160px;

    @media (max-width: ${BREAKPOINTS.XL}) {
      display: none;
    }
  `,

  PurchasedDate: styled.div`
    width: 100%;
    margin-top: 2px;
    font-size: 12px;
    color: #5c5c5c;

    @media (max-width: ${BREAKPOINTS.MD}) {
      flex-shrink: 0;
      width: auto;
    }
  `,

  Event: styled.div`
    @media (max-width: ${BREAKPOINTS.XL}) {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      min-width: 0;
      padding-right: 16px;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: 100%;
      padding-right: 0;
      margin: 8px 0;
    }
  `,
};

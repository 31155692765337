import { GLOBAL_COLORS } from './COLORS';

const ANNOUNCEMENT_EVERYWHERE_STATUS = 40;
const ANNOUNCEMENT_SALED_STATUS = 50;
const ANNOUNCEMENT_BURNED_STATUS = 60;
const ANNOUNCEMENT_GLASS_STATUS = 70;

const ANNOUNCEMENT_STATUS_COLORS: { [key: number]: string } = {
  10: '#EB5757',
  20: '#2F80ED',
  30: '#F09E00',
  40: '#2DC472',
  70: '#5C5C5C',
};

const ANNOUNCEMENT_STATUS_TAG_COLORS: {
  [key: number]: { border: string; color: string; background: string };
} = {
  10: {
    border: GLOBAL_COLORS.red3,
    color: GLOBAL_COLORS.red6,
    background: GLOBAL_COLORS.colorErrorBg,
  },
  20: {
    border: GLOBAL_COLORS.geekblue3,
    color: GLOBAL_COLORS.geekblue6,
    background: GLOBAL_COLORS.geekblue1,
  },
  30: {
    border: GLOBAL_COLORS.orange3,
    color: GLOBAL_COLORS.orange6,
    background: GLOBAL_COLORS.orange1,
  },
  40: {
    border: GLOBAL_COLORS.green3,
    color: GLOBAL_COLORS.green6,
    background: GLOBAL_COLORS.green1,
  },
  50: {
    background: GLOBAL_COLORS.colorFillQuaternary,
    border: GLOBAL_COLORS.colorBorder,
    color: GLOBAL_COLORS.colorText,
  },
  60: {
    background: 'transparent',
    border: 'transparent',
    color: GLOBAL_COLORS.red6,
  },
  70: {
    border: 'transparent',
    color: '#5C5C5C',
    background: 'transparent',
  },
};

const ANNOUNCEMENT_STATUS_NAME: { [key: number]: string } = {
  10: 'Скрыто',
  20: 'Биржа',
  30: 'Виджет',
  40: 'Везде',
  50: 'Продано',
  60: 'Гарь',
  70: 'Стекло',
};

const ANNOUNCEMENT_STATUS = [
  {
    value: 10,
    label: ANNOUNCEMENT_STATUS_NAME[10],
    color: ANNOUNCEMENT_STATUS_COLORS[10],
    tooltip: 'Скрывать везде',
  },
  {
    value: 20,
    label: ANNOUNCEMENT_STATUS_NAME[20],
    color: ANNOUNCEMENT_STATUS_COLORS[20],
    tooltip: 'Выводить только на бирже',
  },
  {
    value: 30,
    label: ANNOUNCEMENT_STATUS_NAME[30],
    color: ANNOUNCEMENT_STATUS_COLORS[30],
    tooltip: 'Выводить только в виджете',
  },
  {
    value: 40,
    label: ANNOUNCEMENT_STATUS_NAME[40],
    color: ANNOUNCEMENT_STATUS_COLORS[40],
    tooltip: 'Выводить на бирже и в виджете',
  },
  {
    value: 70,
    label: ANNOUNCEMENT_STATUS_NAME[70],
    color: ANNOUNCEMENT_STATUS_COLORS[70],
    hidden: true,
  },
];

const ANNOUNCEMENT_STATUS_DEFAULT_SELECT = [
  { value: 10, label: 'Скрыто', id: 10 },
  { value: 20, label: 'Биржа', id: 20 },
  { value: 30, label: 'Виджет', id: 30 },
  { value: 40, label: 'Везде', id: 40 },
  { value: 50, label: 'Завершено', id: 50 },
  { value: 60, label: 'Гарь', id: 60 },
];

const ANNOUNCEMENT_STATUS_SELECT_IN = [
  {
    label: 'Действующие',
    title: 'actual',
    options: [
      { value: 10, label: 'Скрыто', id: 10 },
      { value: 20, label: 'Биржа', id: 20 },
      { value: 30, label: 'Виджет', id: 30 },
      { value: 40, label: 'Везде', id: 40 },
    ],
  },
  {
    label: 'Завершенные',
    title: 'done',
    options: [
      { value: 50, label: 'Завершено', id: 50 },
      { value: 60, label: 'Гарь', id: 60 },
    ],
  },
];

const ANNOUNCEMENT_STATUS_ARRAY = {
  10: ANNOUNCEMENT_STATUS[0],
  20: ANNOUNCEMENT_STATUS[1],
  30: ANNOUNCEMENT_STATUS[2],
  40: ANNOUNCEMENT_STATUS[3],
  70: ANNOUNCEMENT_STATUS[4],
};

export {
  ANNOUNCEMENT_STATUS,
  ANNOUNCEMENT_STATUS_ARRAY,
  ANNOUNCEMENT_EVERYWHERE_STATUS,
  ANNOUNCEMENT_SALED_STATUS,
  ANNOUNCEMENT_STATUS_DEFAULT_SELECT,
  ANNOUNCEMENT_STATUS_SELECT_IN,
  ANNOUNCEMENT_BURNED_STATUS,
  ANNOUNCEMENT_GLASS_STATUS,
  ANNOUNCEMENT_STATUS_NAME,
  ANNOUNCEMENT_STATUS_COLORS,
  ANNOUNCEMENT_STATUS_TAG_COLORS,
};

import React from 'react';
import { IState } from '../../../interfaces/resources';
import DataPreview from '../DataPreview';
import Pagination from './Pagination';

interface PaginationWrapperProps {
  children: any;
  loadMore?: () => void;
  meta: any;
  state: Partial<IState>;
  Wrapper?: any;
  length: number;
  limits?: number[];
}

const PaginationWrapper = ({
  children,
  loadMore,
  meta,
  Wrapper,
  state,
  length,
  limits = [25, 50, 100, 150],
}: PaginationWrapperProps) => (
  <DataPreview length={length} state={state}>
    {children}
    {Wrapper ? (
      <Wrapper>
        <Pagination loadMore={loadMore} meta={meta} state={state} limits={limits} updateQuery />
      </Wrapper>
    ) : (
      <Pagination loadMore={loadMore} meta={meta} state={state} limits={limits} updateQuery />
    )}
  </DataPreview>
);

export default PaginationWrapper;

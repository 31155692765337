import React, { useRef, useEffect } from 'react';
import scrollIntoView from 'scroll-into-view';
import ProgressBarButton from './ProgressBarButton';
import Styled from './styles';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';

interface BarProps {
  steps: string[];
  currentStep: number;
  isVertical?: boolean;
  isMobile?: boolean;
  mobileHorizontal?: boolean;
  onSelect: (id: number) => void;
  invalidSteps?: any;
  stepsPreview?: any;
  getKeyFromStep?: boolean;
  createMode?: boolean;
  horizontalOn?: string;
  disableStepSelect?: number[];
  tooltipText?: string;
}

const ProgressBar: React.FC<BarProps> = ({
  isVertical,
  mobileHorizontal,
  steps,
  currentStep,
  onSelect,
  isMobile,
  invalidSteps = {},
  stepsPreview,
  getKeyFromStep,
  horizontalOn,
  createMode,
  disableStepSelect = [],
  tooltipText,
}) => {
  const progressRef = useRef<any>();

  useEffect(() => {
    if (progressRef && progressRef.current && isMobile && mobileHorizontal) {
      const offset = progressRef.current.children[currentStep].offsetLeft;

      scrollIntoView(progressRef.current, {
        time: 500,
        align: {
          leftOffset: -offset,
        },
      });
    }
  }, [currentStep]);

  return (
    <Styled.Progress isVertical={isVertical} mobileHorizontal={mobileHorizontal} ref={progressRef}>
      {steps.map((step, i) => {
        const idx = getKeyFromStep ? steps.findIndex((stepItem) => stepItem === step) : i;
        const active = idx <= currentStep;
        const current = idx === currentStep;
        const isInvalid = invalidSteps[step];
        const disabledSelect = disableStepSelect && disableStepSelect.includes(idx);

        return (
          <Styled.Wrapper
            // eslint-disable-next-line react/no-array-index-key
            key={`${step}-${idx}`}
            active={active}
            current={current}
            isVertical={isVertical}
            horizontalOn={horizontalOn}
            mobileHorizontal={mobileHorizontal}
            isHidden={step === null}
          >
            <ProgressBarButton
              active={active}
              tooltipText={tooltipText}
              current={current}
              isInvalid={isInvalid}
              step={step}
              isVertical={isVertical}
              horizontalOn={horizontalOn}
              mobileHorizontal={mobileHorizontal}
              onSelect={createMode ? active && !disabledSelect && onSelect : !disabledSelect && onSelect}
              id={idx}
              stepsPreview={stepsPreview}
            />
          </Styled.Wrapper>
        );
      })}
    </Styled.Progress>
  );
};

export default withCheckIsMobile(ProgressBar);

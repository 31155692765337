import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeaderSearch from 'components/Search';
import { invoicesSources } from 'entities/invoices';
import { searchSources } from 'entities/search/sources';
import STORAGE_TOKENS from 'shared/constants/STORAGE_TOKENS';
import api from 'shared/services/api';
import { IUser } from '../../entities/account/types';

function getHistory() {
  if (typeof window === 'undefined') return [];

  try {
    return JSON.parse(localStorage.getItem(STORAGE_TOKENS.SEARCH_HISTORY)!) || [];
  } catch (e) {
    return [];
  }
}

const INITIAL_STATE = {
  invoices: {
    results: [],
    meta: {},
  },
  events: {
    results: [],
    meta: {},
  },
  pool: {
    results: [],
    meta: {},
  },
  orders: {
    results: [],
    meta: {},
  },
  announcements: {
    results: [],
    meta: {},
  },
  state: {
    isLoading: false,
  },
};

interface ContainerProps {
  callContextIsNotEmpty: boolean;
  orderContextIsNotEmpty: boolean;
  account: IUser;
  sidebarIsOpen: boolean;
}

interface ContainerState {
  invoices: {
    results: any[];
    meta: any;
  };
  events: {
    results: any[];
    meta: any;
  };
  pool: {
    results: any[];
    meta: any;
  };
  orders: {
    results: any[];
    meta: any;
  };
  announcements: {
    results: any[];
    meta: any;
  };
  state: {
    isLoading: boolean;
  };
  history: any;
}

class HeaderSearchContainer extends Component<ContainerProps, ContainerState> {
  constructor(props: ContainerProps) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
      history: getHistory(),
    };

    this.loadResults = debounce(this.loadResults.bind(this), 500);
  }

  handleChangeInput = (value) => {
    if (!this.state.state.isLoading) {
      this.setState({
        state: {
          isLoading: true,
        },
      });
    }

    this.loadResults(value)?.catch((err) => new Error(err));
  };

  saveHistory = (search) => {
    const { history } = this.state;

    const historyItem = history.find((item) => item === search);

    const newHistory = historyItem
      ? [historyItem, ...history.filter((item) => item !== historyItem)]
      : [search, ...history.slice(0, 3)];

    this.setState({
      history: newHistory,
    });
    localStorage.setItem(STORAGE_TOKENS.SEARCH_HISTORY, JSON.stringify(newHistory));
  };

  clearHistory = () => {
    this.setState({
      history: [],
    });
    localStorage.removeItem(STORAGE_TOKENS.SEARCH_HISTORY);
  };

  clearResults = () => {
    this.setState({ ...INITIAL_STATE });
  };

  async loadResults(search) {
    let invoices;
    let events;
    let pool;
    let orders;
    let announcements;

    try {
      const {
        data: { results, ...meta },
      } = await api.get(invoicesSources.search, { params: { search_string: search, limit: 3 } });
      invoices = { results, meta };
    } catch (e) {
      invoices = { results: [], meta: {} };
    }

    try {
      const {
        data: {
          data: { results, ...meta },
        },
      } = await api.get(searchSources.events, { params: { search, limit: 3 } });
      events = { results, meta };
    } catch (e) {
      events = { results: [], meta: {} };
    }

    try {
      const {
        data: {
          data: { results, ...meta },
        },
      } = await api.get(searchSources.poolEvents, { params: { search, limit: 3 } });
      pool = { results, meta };
    } catch (e) {
      pool = { results: [], meta: {} };
    }

    try {
      const {
        data: {
          data: { results, ...meta },
        },
      } = await api.get(searchSources.orders, { params: { search, limit: 3 } });
      orders = { results, meta };
    } catch (e) {
      orders = { results: [], meta: {} };
    }

    try {
      const {
        data: {
          data: { results, ...meta },
        },
      } = await api.get(searchSources.announcementEvents, { params: { search, limit: 3 } });
      announcements = { results, meta };
    } catch (e) {
      announcements = { results: [], meta: {} };
    }

    this.setState({
      invoices,
      events,
      pool,
      orders,
      announcements,
      state: {
        isLoading: false,
      },
    });
  }

  render() {
    const { invoices, events, pool, orders, announcements, state, history } = this.state;
    const { callContextIsNotEmpty, orderContextIsNotEmpty, account, sidebarIsOpen } = this.props;

    return (
      <HeaderSearch
        onChange={this.handleChangeInput}
        clearResults={this.clearResults}
        callContextIsNotEmpty={callContextIsNotEmpty}
        orderContextIsNotEmpty={orderContextIsNotEmpty}
        invoices={invoices}
        events={events}
        pool={pool}
        orders={orders}
        announcements={announcements}
        state={state}
        account={account}
        sidebarIsOpen={sidebarIsOpen}
        history={history}
        saveHistory={this.saveHistory}
        clearHistory={this.clearHistory}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account.data,
  sidebarIsOpen: state.layout.sidebarIsOpen,
  callContextIsNotEmpty: !isEmpty(state.callContext.data),
  orderContextIsNotEmpty: Boolean(state.orderContext.data),
});

export default connect(mapStateToProps)(HeaderSearchContainer);

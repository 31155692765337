import styled from 'styled-components';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';

export default {
  Container: styled.div`
    margin-left: 9px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    & svg {
      width: 12px;
      height: 12px;
    }

    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      width: 40px;
      height: 40px;
    }
  `,

  Text: styled.div`
    @media ${MEDIA_QUERIES.MOBILE} {
      max-width: 200px;
    }
  `,
};

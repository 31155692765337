import React from 'react';
import { Invoice as InvoiceType } from 'entities/invoices';
import routes from 'shared/helpers/routes';
import InvoiceIcon from '../../../static/Sidebar/Calls.svg';
import { IUser } from '../../entities/account/types';
import { Invoice } from './Invoice';
import SearchList from './SearchList';

interface InvoicesProps {
  account: IUser;
  data: InvoiceType[];
  meta: {
    limit: number;
    count: number;
    page: number;
    last_page: number;
  };
  search: string;
  sidebarIsOpen: boolean;
  saveHistory: () => void;
  hideMenu: () => void;
}

const Invoices: React.FC<InvoicesProps> = ({ data, meta, search, saveHistory, hideMenu }) => {
  if (!data.length) return null;

  return (
    <SearchList
      length={data.length}
      meta={meta}
      title="Заявки"
      href={routes.searchInvoices}
      search={search}
      saveHistory={saveHistory}
      hideMenu={hideMenu}
      icon={<InvoiceIcon />}
    >
      {data.map((invoice) => (
        <Invoice key={invoice.id} invoice={invoice} saveHistory={saveHistory} />
      ))}
    </SearchList>
  );
};

export default Invoices;

import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { connect } from 'react-redux';
import { ANNOUNCEMENT_STATUS_ARRAY } from 'shared/constants/ANNOUNCEMENT_STATUS';
import preventClick from 'shared/helpers/preventClick';
import BuildingIcon from '../../../static/icons/building.svg';
import EmailIcon from '../../../static/icons/email.svg';
import PhoneIcon from '../../../static/icons/phone.svg';
import UserTieIcon from '../../../static/icons/user-tie.svg';
import { IUser } from '../../entities/account/types';
import StatusDropdown from '../StatusDropdown';
import Tooltip from '../Tooltip';
import Styled from './styles';

interface Props {
  data?: any;
  fallback?: any;
  account: IUser;
  manager?: any;
  status?: number;
  withoutTooltip?: boolean;
  withoutStatus?: boolean;
  withPhone?: boolean;
  withSimpleTooltip?: boolean;
  maxWidth?: number;
  inTable?: boolean;
  switchToTablet?: boolean;
  isBTB?: boolean;
  emptyText?: string;
  withIcons?: boolean;
}

const renderTooltip = (data) => {
  if (!data) return null;

  const incorrectPhone = data.phone && data.phone.includes('undefined');

  return (
    <>
      <Styled.Name>{data.name}</Styled.Name>
      <Styled.Info>
        {data.phone && !incorrectPhone && (
          <Styled.Row>
            <Styled.Text>{data.phone}</Styled.Text>
          </Styled.Row>
        )}
        {data.email && (
          <Styled.Row>
            <Styled.Text>
              <a href={`mailto:${data.email}`}>{data.email}</a>
            </Styled.Text>
          </Styled.Row>
        )}
      </Styled.Info>
      {!data.name && !data.phone && !data.email && <Styled.Empty>Нет информации</Styled.Empty>}
    </>
  );
};

const renderManagerTooltip = (manager) => (
  <>
    {manager.company && (
      <Styled.Row>
        <BuildingIcon />
        <Styled.Text>{manager.company}</Styled.Text>
      </Styled.Row>
    )}
    <Styled.Name>
      {manager.first_name} {manager.last_name}
    </Styled.Name>
  </>
);

const TableUserInfo: React.FC<Props> = ({
  data = null,
  fallback = null,
  account,
  manager = null,
  status = null,
  withoutTooltip = false,
  withPhone = false,
  withoutStatus = false,
  maxWidth = null,
  withSimpleTooltip = false,
  inTable = false,
  switchToTablet = false,
  isBTB = false,
  emptyText = 'Нет информации',
  withIcons = false,
}) => {
  let currentData = data;

  if (isEmpty(data)) {
    currentData = fallback;
  }

  if (!isEmpty(data) && isBTB) {
    currentData = fallback;
  }

  if (!currentData) return <Styled.Empty>Нет информации</Styled.Empty>;

  const { name, email, phone } = currentData;

  const incorrectPhone = phone && phone.includes('undefined');
  const namePreview = name || email || phone || <Styled.Empty>{emptyText}</Styled.Empty>;

  const content = (
    <Styled.Username inTable={inTable} switchToTablet={switchToTablet}>
      {!withoutStatus && currentData && currentData.name === account.company?.name ? (
        <Styled.Status>
          <Styled.StatusLabel>Вы</Styled.StatusLabel>
          {status && (
            <StatusDropdown
              readonly
              label={ANNOUNCEMENT_STATUS_ARRAY[status].label}
              defaultValue={ANNOUNCEMENT_STATUS_ARRAY[status]}
            />
          )}
        </Styled.Status>
      ) : (
        currentData && (
          <>
            {withSimpleTooltip ? (
              <Tooltip text={namePreview}>
                <span>{namePreview}</span>
              </Tooltip>
            ) : (
              namePreview
            )}
          </>
        )
      )}
      {withPhone && currentData && currentData.phone && !incorrectPhone && (
        <>
          {withSimpleTooltip ? (
            <Tooltip text={currentData.phone}>
              <Styled.Phone>{currentData.phone}</Styled.Phone>
            </Tooltip>
          ) : (
            <Styled.Phone>{currentData.phone}</Styled.Phone>
          )}
        </>
      )}
      {withIcons && (
        <Styled.Icons>
          {currentData && currentData.phone && <PhoneIcon width={8} height={12} />}
          {currentData && currentData.email && <EmailIcon width={12} height={12} />}
        </Styled.Icons>
      )}
    </Styled.Username>
  );

  return (
    <Styled.Container
      inTable={inTable}
      maxWidth={maxWidth}
      onClick={preventClick}
      disabled={currentData && currentData.name === account.company.name}
      switchToTablet={switchToTablet}
    >
      {withoutTooltip ? (
        content
      ) : (
        <Tooltip text={renderTooltip(currentData)} isWhite isBig delay={0.1} placement="left">
          {content}
        </Tooltip>
      )}
      {manager && manager.first_name && manager.last_name ? (
        <Tooltip text={renderManagerTooltip(manager)} isWhite isBig placement="left">
          <Styled.Manager>
            <Styled.ManagerIcon>
              <UserTieIcon opacity={0.4} />
            </Styled.ManagerIcon>
            <span>
              {manager.first_name} {manager.last_name}
            </span>
          </Styled.Manager>
        </Tooltip>
      ) : (
        manager && (
          <Styled.Manager>
            <Styled.ManagerIcon>
              <UserTieIcon opacity={0.4} />
            </Styled.ManagerIcon>
            <span>Без имени</span>
          </Styled.Manager>
        )
      )}
    </Styled.Container>
  );
};

const mapStateToProps = (state) => ({
  account: state.account.data,
});

export default connect(mapStateToProps)(TableUserInfo);

import { ButtonProps } from 'antd';
import React from 'react';
import { BUTTON_VARIANTS, COLORS_BY_DEFAULT_COLOR_PROP, TButtonVariant } from './constants';
import { ButtonStyled } from './styled';

export type TButtonProps = Omit<ButtonProps, 'variant'> & {
  variant?: TButtonVariant;
  onlyIcon?: boolean;
  circle?: boolean;
  fullWidth?: boolean;
};

export const Button = (props: TButtonProps) => {
  const { circle, children, size = 'large', color, variant = 'default', onlyIcon, ...rest } = props;

  const getButtonShape = () => {
    if (circle) {
      return onlyIcon ? 'circle' : 'round';
    }
    return 'default';
  };

  const buttonColor = !color ? COLORS_BY_DEFAULT_COLOR_PROP[variant] : color;
  const buttonVariant = BUTTON_VARIANTS[variant];

  return (
    <ButtonStyled shape={getButtonShape()} size={size} color={buttonColor} variant={buttonVariant} {...rest}>
      {!onlyIcon && children}
    </ButtonStyled>
  );
};

import styled, { css, keyframes } from 'styled-components';
import { scrollbar } from '../../app/styles/scrollbar';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import { GLOBAL_COLORS } from 'shared/constants/COLORS';

interface IconContainerProps {
  isOpen?: boolean;
}

interface DropdownProps {
  isOpen?: boolean;
}

interface NotificationIconProps {
  bg?: string;
}

interface BellProps {
  hasNotifications?: boolean;
}

interface ButtonWrapperProps {
  hasNotifications?: boolean;
}

const djingle = keyframes`
  0%,
  2% {
    transform: translateX(-3px) rotate(6deg);
  }
  3% {
    transform: translateX(2px) rotate(-6deg);
  }
  6% {
    transform: translateX(-2px) rotate(3.6deg);
  }
  8% {
    transform: translateX(1px) rotate(-2.4deg);
  }
  11% {
    transform: translateX(-1px) rotate(1.2deg);
  }
  13% {
    transform: translateX(-3px) rotate(6deg);
  }
  15% {
    transform: translateX(2px) rotate(-6deg);
  }
  17% {
    transform: translateX(-2px) rotate(3.6deg);
  }
  20% {
    transform: translateX(1px) rotate(-2.4deg);
  }
  22% {
    transform: translateX(-1px) rotate(1.2deg);
  }
  24% {
    transform: translateX(-3px) rotate(6deg);
  }
  26% {
    transform: translateX(2px) rotate(-6deg);
  }
  28% {
    transform: translateX(-2px) rotate(3.6deg);
  }
  30% {
    transform: translateX(1px) rotate(-2.4deg);
  }
  33% {
    transform: translateX(-1px) rotate(1.2deg);
  }
  100% {
    transform: translateX(0%);
    transform-origin: 50% 50%;
  }
`;

export default {
  Container: styled.div`
    position: relative;
  `,

  Audio: styled.audio<any>`
    display: none;
  `,

  IconContainer: styled.button<IconContainerProps>`
    width: 40px;
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    outline: none;
    border: 0;
    background-color: transparent;

    &:hover {
      background: #f5f5f5;
    }

    background-color: ${({ isOpen }) => isOpen && '#f5f5f5'};
  `,

  Dropdown: styled.div<DropdownProps>`
    top: 50px;
    right: -12px;
    position: absolute;
    width: 440px;
    max-height: 600px;
    padding-top: 8px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
    opacity: 0;
    transition: all 0.2s ease-in-out;

    opacity: ${({ isOpen }) => isOpen && 1};
    pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};

    &:after {
      content: '';
      width: 14px;
      height: 14px;
      position: absolute;
      top: -14px;
      right: 24px;
      border-top: 7px solid rgba(0, 0, 0, 0);
      border-bottom: 7px solid #fff;
      border-left: 7px solid rgba(0, 0, 0, 0);
      border-right: 7px solid rgba(0, 0, 0, 0);
      box-sizing: border-box;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-top: 0;
      z-index: 1;
    }
  `,

  Title: styled.div`
    display: flex;
    align-items: center;
    height: 56px;
    font-size: 16px;
    line-height: 21px;
    border-bottom: 1px solid #e1e1e1;

    @media (min-width: ${BREAKPOINTS.MD}) {
      display: none;
    }
  `,

  TitleIcon: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  `,

  Close: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-left: auto;
  `,

  Empty: styled.div`
    margin-bottom: 8px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  `,

  NotificationList: styled.div`
    max-height: 384px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    ${scrollbar};
  `,

  Notification: styled.div`
    padding: 12px 24px 12px 24px;
    min-height: 56px;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    &:hover {
      background: #f5f5f5;
    }
  `,

  NotificationIcon: styled.div<NotificationIconProps>`
    width: 32px;
    height: 32px;
    background-color: #ebebeb;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${({ bg }) => bg && bg};
  `,

  NotificationData: styled.div`
    display: flex;
    flex-grow: 1;
  `,

  NotificationInfo: styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 16px;
  `,

  NotificationText: styled.div`
    font-size: 14px;
  `,

  NotificationDate: styled.div`
    color: #5c5c5c;
    font-size: 12px;
  `,

  NotificationDownload: styled.div`
    margin-left: auto;
    font-size: 14px;
    color: #2f80ed;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    border-radius: 4px;
    align-self: center;
    cursor: pointer;

    & > button > svg {
      margin-left: 8px;
    }

    &:hover {
      background-color: #eaf2fd;
    }

    & > button {
      outline: none;
      padding: 0 7px;
      height: 24px;
    }
  `,

  Footer: styled.div`
    display: flex;
    margin-top: 8px;
    border-top: 1px solid #e1e1e1;
    justify-content: center;
  `,

  ReadAll: styled.button`
    width: 100%;
    background: none;
    height: 40px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    outline: none;

    &:hover {
      background-color: #f5f5f5;
    }
  `,

  History: styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-grow: 1;
    width: 168px;
    height: 40px;
    border: 0;
    font-size: 14px;
    background: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    outline: none;

    &:hover {
      background-color: #f5f5f5;
    }
  `,

  ButtonWrapper: styled.div<ButtonWrapperProps>`
    button {
      border-radius: 50%;

      ${({ hasNotifications }) =>
        hasNotifications &&
        css`
          background-color: rgba(235, 87, 87, 0.16);

          &:focus,
          &:hover:not(:disabled),
          &:active:not(:disabled) {
            background-color: rgba(235, 87, 87, 0.16);
          }
        `};
    }
  `,

  Bell: styled.div<BellProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ hasNotifications }) =>
      hasNotifications &&
      css`
        color: ${GLOBAL_COLORS.colorError};
        animation: ${djingle} 6s infinite both;
      `};
  `,

  UserIcon: styled.span`
    padding: 0 4px;
  `,

  Link: styled.a`
    padding: 0 4px;
    color: #2f80ed;
  `,
};

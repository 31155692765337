import React from 'react';
import EventInfo from 'components/EventInfo';
import InvoicesManager from 'components/Pages/Invoices/InvoicesManager';
import InvoicesShortInfo from 'components/Pages/Invoices/InvoicesShortInfo';
import InvoicesStatus from 'components/Pages/Invoices/InvoicesStatus';
import TableUserInfo from 'components/TableUserInfo';
import { Invoice as InvoiceType } from 'entities/invoices';
import { formatDateFromNow } from 'shared/helpers/date';
import routes from 'shared/helpers/routes';
import InvoiceIcon from '../../../../static/Sidebar/Calls.svg';
import Styled from './styles';

interface Props {
  invoice: InvoiceType;
  saveHistory: () => void;
}

export const Invoice: React.FC<Props> = ({ invoice, saveHistory }) => {
  return (
    <Styled.Link href={`${routes.invoices}?id=${invoice.id}`} onClick={saveHistory}>
      <Styled.Row>
        <Styled.Info>
          <Styled.Icon>
            <InvoiceIcon />
          </Styled.Icon>
          <Styled.Id>{invoice.id}</Styled.Id>
          <Styled.Date>{formatDateFromNow(invoice.created)}</Styled.Date>
          <Styled.TableUserInfo>
            <TableUserInfo data={invoice.user_customer} withIcons />
          </Styled.TableUserInfo>
        </Styled.Info>
        <Styled.EventInfo>
          <EventInfo
            date={invoice.event?.started_at}
            name={invoice.event?.title}
            place={invoice.event?.place?.title}
          />
        </Styled.EventInfo>
        <Styled.Count>{invoice.count || '-'}</Styled.Count>
        <Styled.StatusAndManager>
          <Styled.InvoiceStatusAndShortInfo>
            <InvoicesStatus data={invoice} />
            <InvoicesShortInfo data={invoice} />
          </Styled.InvoiceStatusAndShortInfo>
          <Styled.Manager>
            <InvoicesManager data={invoice} />
          </Styled.Manager>
        </Styled.StatusAndManager>
      </Styled.Row>
    </Styled.Link>
  );
};

import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { Button } from 'shared/ui/button';
import EngIcon from '../../../static/icons/flags/english-flag.svg';
import RuIcon from '../../../static/icons/flags/russian-flag.svg';

const LangSwitcher = () => {
  const { asPath, locale } = useRouter();
  const newLang = locale === LANGUAGES.EN ? LANGUAGES.RU : LANGUAGES.EN;

  return (
    <Link href={asPath} locale={newLang}>
      <Button
        icon={locale === LANGUAGES.RU ? <RuIcon /> : <EngIcon />}
        variant="text"
        htmlType="button"
        circle
        onlyIcon
      />
    </Link>
  );
};

export default LangSwitcher;

import React from 'react';
import CaretRight from '../../../static/icons/caret-right.svg';
import Link from '../Link';
import SearchStyled from './styles';

interface ListProps {
  // eslint-disable-next-line no-undef
  children: JSX.Element[];
  title: string;
  // eslint-disable-next-line no-undef
  icon: JSX.Element;
  href: string;
  search: string;
  length: number;
  meta: any;
  saveHistory: () => void;
  hideMenu: () => void;
}

const SearchList: React.FC<ListProps> = ({
  children,
  icon,
  title,
  href,
  search,
  length,
  meta,
  saveHistory,
  hideMenu,
}) => {
  const hasMore = meta.count - length > 0;

  const handleClick = () => {
    saveHistory();
    hideMenu();
  };

  return (
    <>
      <Link
        href={`${href}?${href.includes('invoices') ? 'search_string' : 'search'}=${search}`}
        onClick={handleClick}
      >
        <SearchStyled.Title>
          <div>
            <SearchStyled.TypeIcon>{icon}</SearchStyled.TypeIcon>
            {title}
          </div>
          {hasMore && (
            <div>
              {`еще ${meta.count - length}`}
              <SearchStyled.Caret>
                <CaretRight />
              </SearchStyled.Caret>
            </div>
          )}
        </SearchStyled.Title>
      </Link>
      <SearchStyled.Results>{children}</SearchStyled.Results>
    </>
  );
};

export default SearchList;

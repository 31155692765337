import isEqual from 'lodash/isEqual';
import { useTranslation } from 'next-i18next';
import React from 'react';
import Button from 'components/Button';
import { AttachmentType } from 'shared/constants/ATTACHMENTS_TYPE';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { getAttachments } from 'shared/lib/getAttachments';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import api from 'shared/services/api';
import {
  createLandingAttachments,
  deleteLandingAttachments,
  editLandingAttachments,
} from 'shared/sources/attachments';
import landingsPlaygroundsSources from 'shared/sources/landings/landingsPlaygrounds';
import TrashIcon from '../../../../../static/icons/trash-alt.svg';
import { getDifferenceInContentGroups, getTranslations } from '../../helpers/landingHelpers';
import { IAttachmentTypes } from '../../types';
import { Place } from '../../types/Place';
import PlacesForm from './PlacesForm';

interface PlacesUpdateProps {
  data: Place;
  onSuccess: (data: any) => any;
  onChangeLng: () => any;
  showDeleteModal: () => any;
  isMobile: boolean;
  lng: string;
  closeModal: () => any;
  attachmentTypes: IAttachmentTypes;
}

const PlacesUpdate: React.FC<PlacesUpdateProps> = (props) => {
  const { data, onSuccess, onChangeLng, showDeleteModal, isMobile, lng, closeModal, attachmentTypes } = props;
  const { t } = useTranslation();

  const cover = data.attachments?.find((attachement) => {
    return attachement.attachment_type.code === AttachmentType.Cover;
  });
  const gallery = data.attachments?.find((attachement) => {
    return attachement.attachment_type.code === AttachmentType.Gallery;
  });
  const preview = data.attachments?.find((attachement) => {
    return attachement.attachment_type.code === AttachmentType.Preview;
  });
  const coverInfo = cover?.info.find((i) => i.language_code === lng);
  const previewInfo = preview?.info.find((i) => i.language_code === lng);
  const info = getTranslations(data.info, lng);

  const initialValues = {
    id: data.id,
    title: info?.title,
    address: info?.address,
    description: info?.description,
    annotation: info?.annotation,
    contentGroups:
      (data.content_groups &&
        data.content_groups
          .filter((i) => i.id)
          .map((item) => ({
            value: item.id,
            label: item.title,
          }))) ||
      [],
    place: data.place && {
      label: data.place.title,
      value: data.place.id,
    },
    cover: cover?.data,
    preview: preview?.data,
    gallery: gallery?.data,
    ...(coverInfo && { cover_title: coverInfo.title, cover_alt: coverInfo.alt }),
    ...(previewInfo && { preview_title: previewInfo.title, preview_alt: previewInfo.alt }),
    qna:
      data.qna.map((item) => ({
        value: item.id,
        label: item.title,
      })) || [],
  };

  const handleUpdate = async (submitData) => {
    const formLang = submitData.lng;
    const formData = submitData[formLang];
    if (formLang === LANGUAGES.RU && isEqual(formData, initialValues)) {
      return {};
    }

    const translations = getTranslations(data.info, formLang);

    if (translations) {
      await landingsPlaygroundsSources.updateTranslation(data.id, formLang, formData);
    } else {
      await landingsPlaygroundsSources.createTranslation(data.id, { ...formData, language_code: formLang });
    }

    const attachments = getAttachments({
      data,
      formValue: submitData,
      attachmentTypes,
    });

    if (attachments?.createAttachment) {
      await createLandingAttachments({ ...attachments?.createAttachment, page: 'places' });
    }

    if (attachments?.updateAttachment) {
      await editLandingAttachments({ ...attachments?.updateAttachment, page: 'places' });
    }

    if (attachments?.deleteAttachment) {
      await deleteLandingAttachments({ ...attachments?.deleteAttachment, page: 'places' });
    }

    if (formLang !== LANGUAGES.RU) {
      return {};
    }

    const { contentGroupsforDelete, contentGroupsforUpdate, qnaforUpdate, qnaforDelete } =
      getDifferenceInContentGroups({
        initialData: initialValues,
        formData,
      });

    if (contentGroupsforDelete.length) {
      landingsPlaygroundsSources.deleteContentGroups(data.id, contentGroupsforDelete);
    }

    if (contentGroupsforUpdate.length) {
      const groupsResponse: any = await landingsPlaygroundsSources
        .createContentGroups(data.id, contentGroupsforUpdate)
        .catch((error) => error);

      if (groupsResponse.response?.data?.detail.includes('Place with content group')) {
        return {
          error: {
            response: {
              data: {
                error: ['Данная площадка с таким тегом существует'],
              },
            },
          },
        };
      }
    }

    if (qnaforUpdate.length) {
      landingsPlaygroundsSources.createQnA(data.id, qnaforUpdate);
    }

    if (qnaforDelete.length) {
      landingsPlaygroundsSources.deleteQnA(data.id, qnaforDelete);
    }

    const response = await api.patch(landingsPlaygroundsSources.detail(data.id), {
      place_id: formData.place.value,
      slug: data.slug,
      company_id: data.company_id,
    });

    return response;
  };

  return (
    <PlacesForm
      isEdit
      title="Редактировать площадку"
      onSubmit={handleUpdate}
      onSuccess={onSuccess}
      initialValues={initialValues}
      onChangeLng={onChangeLng}
      lng={lng}
    >
      <Button dangerTransparent onlyIcon={isMobile} type="button" onClick={showDeleteModal}>
        {isMobile ? <TrashIcon /> : t('forms:delete')}
      </Button>
      <Button type="button" transparent onClick={closeModal}>
        {t('forms:cancel')}
      </Button>
      <Button type="submit">{t('forms:save')}</Button>
    </PlacesForm>
  );
};

export default withCheckIsMobile(PlacesUpdate);

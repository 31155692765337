import React from 'react';
import preventClick from 'shared/helpers/preventClick';
import QuestionIcon from '../../../static/icons/question.svg';
import Button from '../Button';
import Tooltip from '../Tooltip';
import { MobileOption, MobileOptionCheckbox, MobileOptionButton } from './styles';

interface OptionProps {
  children: string;
  onClick: () => void;
  isSelected?: boolean;
  color?: string;
  helper: string;
}

const StatusDropdownMobileOption: React.FC<OptionProps> = ({
  children,
  onClick,
  isSelected = false,
  color = '#5c5c5c',
  helper,
}) => (
  <MobileOption onClick={onClick}>
    <MobileOptionCheckbox color={color} isSelected={isSelected} />
    {children}
    <Tooltip text={helper} placement="left">
      <MobileOptionButton>
        <Button onlyIcon transparent onClick={preventClick}>
          <QuestionIcon />
        </Button>
      </MobileOptionButton>
    </Tooltip>
  </MobileOption>
);

export default StatusDropdownMobileOption;

import { TYPES as REFERENCE_MODALS } from './REFERENCE_MODALS';

export default {
  DEFAULT: 'DEFAULT',

  REFERENCE_COUNTRY_CREATE: REFERENCE_MODALS.COUNTRY_CREATE,
  REFERENCE_COUNTRY_UPDATE: REFERENCE_MODALS.COUNTRY_UPDATE,
  REFERENCE_CURRENCY: REFERENCE_MODALS.CURRENCY,
  REFERENCE_CURRENCY_HISTORY: REFERENCE_MODALS.CURRENCY_HISTORY,
  REFERENCE_EVENT_CATEGORY: REFERENCE_MODALS.EVENT_CATEGORY,
  REFERENCE_EXPANSION: REFERENCE_MODALS.EXPANSION,
  REFERENCE_TAG: REFERENCE_MODALS.TAG,
  REFERENCE_PERSON: REFERENCE_MODALS.PERSONS,
  REFERENCE_SUBDOMAIN_UPDATE: REFERENCE_MODALS.SUBDOMAIN_UPDATE,
  REFERENCE_ERRORS_MODAL: 'REFERENCE_ERRORS_MODAL',
  REFERENCE_ACTUAL: 'REFERENCE_ACTUAL',
  REFERENCE_QnA: 'REFERENCE_QnA',

  COMPANY_MEMBER: 'COMPANY_MEMBER',
  COMPANY_MEMBER_PERMISSIONS: 'COMPANY_MEMBER_PERMISSIONS',
  COMPANY_ADD_PROVIDER: 'COMPANY_ADD_PROVIDER',
  COMPANY_ADD_PAYMENT_METHOD: 'COMPANY_ADD_PAYMENT_METHOD',
  COMPANY_EMAIL_CONFIG_MODAL: 'COMPANY_EMAIL_CONFIG_MODAL',
  COMPANY_LEGAL_INFO_MODAL: 'COMPANY_LEGAL_INFO_MODAL',
  COMPANY_ATOL_CONFIG_MODAL: 'COMPANY_ATOL_CONFIG_MODAL',
  COMPANY_PHONE_MODAL: 'COMPANY_PHONE_MODAL',

  CLIENTS_MODAL: 'CLIENTS_MODAL',
  CLIENTS_MERGE_MODAL: 'CLIENTS_MERGE_MODAL',

  BOOK_TICKETS_MODAL: 'BOOK_TICKETS_MODAL',

  LINK_EVENT_MODAL: 'LINK_EVENT_MODAL',

  ARTICLE_MODAL: 'ARTICLE_MODAL',
  SITE_MODAL: 'SITE_MODAL',
  LANDINGS_CUSTOM_PAGE_MODAL: 'LANDINGS_CUSTOM_PAGE_MODAL',
  PERSONALITY_MODAL: 'PERSONALITY_MODAL',
  TAG_MODAL: 'TAG_MODAL',
  TAG_MERGE_MODAL: 'TAG_MERGE_MODAL',
  LANDINGS_EVENT_MODAL: 'LANDINGS_EVENT_MODAL',
  PLAYGROUND_MODAL: 'PLAYGROUND_MODAL',

  CONSTRUCTOR_MODAL: 'CONSTRUCTOR_MODAL',
  PAGE_CONSTRUCTOR_MODAL: 'PAGE_CONSTRUCTOR_MODAL',
  LANDINGS_TEMPLATE_MODAL: 'LANDINGS_TEMPLATE_MODAL',
  LANDINGS_SITES_EDIT_INFO_MODAL: 'LANDINGS_SITES_EDIT_INFO_MODAL',
  LANDINGS_ERRORS_MODAL: 'LANDINGS_ERRORS_MODAL',
  LANDINGS_QNA_MODAL: 'LANDINGS_QNA_MODAL',
  LANDINGS_TEMPLATE_SET_MODAL: 'LANDINGS_TEMPLATE_SET_MODAL',
  LANDINGS_REDIRECTS_MODAL: 'LANDINGS_REDIRECTS_MODAL',

  BOOKING_UPDATES_MODAL: 'BOOKING_UPDATES_MODAL',
  ORDER_UPDATES_MODAL: 'ORDER_UPDATES_MODAL',
  PARTNER_TICKETS_MODAL: 'PARTNER_TICKETS_MODAL',
  ORDER_CANCEL_MODAL: 'ORDER_CANCEL_MODAL',
  ORDERS_CANCEL_MODAL: 'ORDERS_CANCEL_MODAL',
  ORDERS_ASSIGN_MEMBER: 'ORDERS_ASSIGN_MEMBER',
  ORDER_REFUND_MODAL: 'ORDER_REFUND_MODAL',
  ORDER_EDIT_USER_CUSTOMER_MODAL: 'ORDER_EDIT_USER_CUSTOMER_MODAL',
  ORDER_ASSIGN_MANAGER_MODAL: 'ORDER_ASSIGN_MANAGER_MODAL',
  ORDER_EXTERNAL_ID_MODAL: 'ORDER_EXTERNAL_ID_MODAL',
  ORDER_NEW_TICKETS_MODAL: 'ORDER_NEW_TICKETS_MODAL',
  ORDER_DISCOUNT_MODAL: 'ORDER_DISCOUNT_MODAL',
  ORDER_EDIT_DELIVERY_MODAL: 'ORDER_EDIT_DELIVERY_MODAL',
  ORDER_ADD_NOTIFICATION_MODAL: 'ORDER_ADD_NOTIFICATION_MODAL',
  ORDER_PAYMENT_TIME_MODAL: 'ORDER_PAYMENT_TIME_MODAL',

  INVOICES_ASSIGN_MANAGER_MODAL: 'INVOICES_ASSIGN_MANAGER_MODAL',
  INVOICES_EDIT_USER_CUSTOMER_MODAL: 'INVOICES_EDIT_USER_CUSTOMER_MODAL',
  INVOICES_REJECT_MODAL: 'INVOICES_REJECT_MODAL',
  INVOICES_EDIT_EVENT_MODAL: 'INVOICES_EDIT_EVENT_MODAL',

  CONFIRM_MODAL: 'CONFIRM_MODAL',

  NEW_ANNOUNCEMENT_MODAL: 'NEW_ANNOUNCEMENT_MODAL',
  ANNOUNCEMENT_HISTORY_MODAL: 'ANNOUNCEMENT_HISTORY_MODAL',
  ANNOUNCEMENT_PLANNING_PRICE_MODAL: 'ANNOUNCEMENT_PLANNING_PRICE_MODAL',
  ANNOUNCEMENT_COMMENT_MODAL: 'ANNOUNCEMENT_COMMENT_MODAL',
  ANNOUNCEMENT_PRICE_EDIT_MODAL: 'ANNOUNCEMENT_PRICE_EDIT_MODAL',
  ANNOUNCEMENT_STATUS_EDIT_MODAL: 'ANNOUNCEMENT_STATUS_EDIT_MODAL',

  EVENT_MODAL: 'EVENT_MODAL',
  MERGE_EVENTS_MODAL: 'MERGE_EVENTS_MODAL',

  ROLE_MODAL: 'ROLE_MODAL',

  POOL_SCHEME: 'POOL_SCHEME',

  GLASS_TICKETS_MODAL: 'GLASS_TICKETS_MODAL',

  PREORDER_MODAL: 'PREORDER_MODAL',

  DRAWER_MODAL: 'DRAWER_MODAL',

  PARSERS_ADD_EVENT_MODAL: 'PARSERS_ADD_EVENT_MODAL',
  PARSERS_EXTERNAL_ID_MODAL: 'PARSERS_EXTERNAL_ID_MODAL',

  REPORTS_DESCRIPTION_MODAL: 'REPORTS_DESCRIPTION_MODAL',
  REPORTS_EVENTS_FILTER_MODAL: 'REPORTS_EVENTS_FILTER_MODAL',

  EDIT_MEMBER_MODAL: 'EDIT_MEMBER_MODAL',
  COMPANY_INFO_MODAL: 'COMPANY_INFO_MODAL',

  REPORTS_CONFIG_MODAL: 'REPORTS_CONFIG_MODAL',

  REGISTRATION_NOTICE_MODAL: 'REGISTRATION_NOTICE_MODAL',

  DELETE_MARKUP: 'DELETE_MARKUP',

  FILTERS_EDIT: 'FILTERS_EDIT',
  FILTERS_CREATE: 'FILTERS_CREATE',

  REFERENCE_MARKUP: 'REFERENCE_MARKUP',
  MARKUP_CREATE: 'MARKUP_CREATE',
  MARKUP_UPDATE: 'MARKUP_UPDATE',
};

export const MODALS_NAMES = {
  MARKUPS: 'markups',
};

import { ButtonProps } from 'antd';

export type TButtonVariant = 'text' | 'link' | 'dashed' | 'default' | 'primary' | 'danger' | 'dangerOutlined';

export const BUTTON_VARIANTS: Record<TButtonVariant, ButtonProps['variant']> = {
  primary: 'solid',
  default: 'outlined',
  text: 'text',
  link: 'link',
  dashed: 'dashed',
  danger: 'solid',
  dangerOutlined: 'outlined',
};

export const COLORS_BY_DEFAULT_COLOR_PROP: Record<TButtonVariant, ButtonProps['color']> = {
  primary: 'primary',
  default: 'default',
  text: 'default',
  link: 'default',
  dashed: 'primary',
  danger: 'danger',
  dangerOutlined: 'danger',
};

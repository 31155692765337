import styled from 'styled-components';

export default {
  App: styled.div`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: #000;
    overflow: hidden;
  `,

  Container: styled.div`
    display: flex;
    height: 100%;
    min-height: 0;
    background: #fff;
  `,

  SearchOverlay: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: none;
    background: transparent;
  `,
};

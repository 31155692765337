import React from 'react';
import TextOverflow from '../../../TextOverflow';

const InvoicesManager = ({ data }: any) => {
  const { manager } = data;

  if (manager) {
    return <TextOverflow>{`${manager.first_name || ''} ${manager.last_name || ''}`}</TextOverflow>;
  }

  return <>-</>;
};

export default InvoicesManager;
